import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "tt-menu-tooltip" }
const _hoisted_2 = { class: "tt-menu-tooltip__menu" }
const _hoisted_3 = { class: "tt-menu-tooltip__menu-element" }
const _hoisted_4 = { class: "tt-menu-tooltip__el-text" }
const _hoisted_5 = { class: "tt-menu-tooltip__icon" }
const _hoisted_6 = { class: "tt-menu-tooltip__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.props.item.submenu, (el) => {
        return (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_router_link, {
            class: "tt-menu-tooltip__link tt-menu-tooltip__link--link",
            to: {name: el.link}
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(el.icon), { class: "button_img" }))
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("span", null, _toDisplayString(el.title), 1)
                ])
              ])
            ]),
            _: 2
          }, 1032, ["to"])
        ]))
      }), 256))
    ])
  ]))
}