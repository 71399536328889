import { App } from 'vue'
import { createI18n } from "vue-i18n"

declare global {
  interface Window {
    language: string
    django: {
      catalog: {
        [key: string]: string
      }
    }
    login_url: string
  }
}

const messages = {
  [window.language]: window.django && window.django.catalog || {},
}

type compileMessages = {
  locale: string,
  messages: typeof messages
}

export const compileMessages = (): compileMessages => ({
  locale: window.language,
  messages,
})

const i18nOptions = {
  ...compileMessages(),
  silentTranslationWarn: true,
  missingWarn: false,
  fallbackWarn: false,
  legacy: false,
}

export const i18n = createI18n(i18nOptions)

export default {
  install(app: App<Element>): void {
    app.use(i18n)
  }
}
