import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "cabinet-sidebar-menu__element" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass($setup.classes)
  }, [
    _createElementVNode("ul", _hoisted_1, [
      _renderSlot(_ctx.$slots, "prepend"),
      ($setup.slots.elements)
        ? _renderSlot(_ctx.$slots, "elements", { key: 0 })
        : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList($setup.props.sections, (section) => {
            return (_openBlock(), _createBlock($setup["CabinetSidebarMenuSection"], _mergeProps({ ref_for: true }, { ...section }, {
              condensed: $setup.props.condensed,
              opened: $setup.props.opened,
              drop: $setup.props.dropdownMenu || false
            }), null, 16, ["condensed", "opened", "drop"]))
          }), 256)),
      _renderSlot(_ctx.$slots, "append")
    ])
  ], 2))
}