import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, renderList as _renderList, createSlots as _createSlots, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = {
  key: 0,
  class: "is-required u-sel-none"
}
const _hoisted_4 = {
  key: 0,
  class: "input-icon-element input-icon-element--reset-icon"
}
const _hoisted_5 = { class: "error-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DControlLabel = _resolveComponent("DControlLabel")!
  const _component_VeeError = _resolveComponent("VeeError")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["d-control-descriptor", { error: $props.errors[$props.name], success: $props.isSuccess }])
  }, [
    (_openBlock(), _createBlock(_resolveDynamicComponent("VeeField"), _mergeProps(_ctx.$attrs, {
      name: $props.name,
      rules: $props.rules,
      id: $props.id,
      modelValue: _ctx.$attrs.modelValue,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.$attrs.modelValue) = $event))
    }), {
      default: _withCtx(({ field, handleInput }) => [
        _createElementVNode("div", _hoisted_1, [
          (_openBlock(), _createBlock(_resolveDynamicComponent($setup.inputComponent), _mergeProps({ class: "d-control-descriptor__element" }, { ...$setup.getFP(field), ..._ctx.$attrs, ...$setup.props, ref: "flatPicker"}, {
            labelBy: $props.labelBy,
            "set-value": $props.setValue,
            slots: _ctx.$slots,
            modelValue: _ctx.$attrs.modelValue,
            "onUpdate:modelValue": [
              _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$attrs.modelValue) = $event)),
              $setup.updateModelValue
            ],
            onUpdateModel: $setup.updateValue,
            "onReset:modelValue": $setup.resetValue,
            "onUpdate:isValid": _cache[1] || (_cache[1] = ($event: any) => ($setup.isValid = $event))
          }), _createSlots({
            default: _withCtx(({ picker }) => [
              ($setup.inputComponent.__name === $setup.f && !_ctx.$attrs.modelValue)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "input-icon-element input-icon-element--date pointer",
                    onClick: ($event: any) => (picker.fp.open())
                  }, null, 8, _hoisted_2))
                : _createCommentVNode("", true)
            ]),
            label: _withCtx(() => [
              _createVNode(_component_DControlLabel, _mergeProps({
                class: ["d-control-input__label--static", {'d-control-input__label--disabled': $props.disabled }],
                "input-label": $props.staticLabel ? $props.staticLabel : $props.inputLabel
              }, _ctx.$attrs, { value: $setup.inputValue }), {
                default: _withCtx(() => [
                  ($setup.containsRequired($setup.props.rules))
                    ? (_openBlock(), _createElementBlock("span", _hoisted_3, "*"))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 16, ["class", "input-label", "value"])
            ]),
            _: 2
          }, [
            _renderList($setup.eSlots, (name, key) => {
              return {
                name: name,
                fn: _withCtx((binded) => [
                  _renderSlot(_ctx.$slots, key, _normalizeProps(_guardReactiveProps(binded)))
                ])
              }
            })
          ]), 1040, ["labelBy", "set-value", "slots", "modelValue"])),
          ($setup.inputComponent.__name === $setup.f && _ctx.$attrs.modelValue)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 3
    }, 16, ["name", "rules", "id", "modelValue"])),
    _createVNode(_component_VeeError, { name: $props.name }, {
      default: _withCtx(({ message }) => [
        _createElementVNode("div", _hoisted_5, _toDisplayString(message), 1)
      ]),
      _: 1
    }, 8, ["name"])
  ], 2))
}