import type { Router } from 'vue-router'
import { defineStore } from '@/modules/pinia-hot'

import {
  notificationsCountResource,
} from '@/services/notifications.service'

const id = 'notifications'

export const useNotifications = defineStore(id, {
  state: () => ({
    unreadCount: '',
  }),
  getters: {
  },
  actions: {
    async getUnread() {
      const { data: { items } } = await notificationsCountResource.execute({})
      this.unreadCount = items[0]?.flags.find(flag => flag?.pk === 73232)?.count
    },
  },
})
