import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tt-auth-form" }
const _hoisted_2 = { class: "tt-auth-form__title" }
const _hoisted_3 = { class: "tt-auth-form__wrapper" }
const _hoisted_4 = { class: "error-label error-label----styled" }
const _hoisted_5 = { class: "global-error" }
const _hoisted_6 = { class: "g-row g-row--appearance_spaced g-row--space_sm g-row--space_2xl-xl g-row--align_center" }
const _hoisted_7 = { class: "g-cell g-cols g-cols--12" }
const _hoisted_8 = { class: "g-cell g-cols g-cols--12" }
const _hoisted_9 = { class: "g-cell g-cols g-cols--12" }
const _hoisted_10 = { class: "g-cell g-cols g-cols--12" }
const _hoisted_11 = { class: "tt-button__title" }
const _hoisted_12 = { class: "recaptcha-field g-recaptcha" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loader = _resolveComponent("loader")!
  const _component_VeeError = _resolveComponent("VeeError")!
  const _component_d_control_input = _resolveComponent("d-control-input")!
  const _component_VeeForm = _resolveComponent("VeeForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.load)
      ? (_openBlock(), _createBlock(_component_loader, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", null, _toDisplayString($setup.$t('Password change')), 1)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["tt-auth-form__subtitle", {'centered' : _ctx.codeIsSended}])
    }, [
      _createElementVNode("p", null, _toDisplayString($setup.$t("To change your password, please fill out the fields. After successfully changing your password, you will need to use the new password the next time you log in.")), 1)
    ], 2),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_VeeForm, {
        onSubmit: $setup.request,
        ref: "form"
      }, {
        default: _withCtx(({ errors }) => [
          _createVNode(_component_VeeError, { name: "nonFieldErrors" }, {
            default: _withCtx(({ message }) => [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, _toDisplayString(message), 1)
              ])
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_d_control_input, {
                rules: "required|max:128",
                name: "oldPassword",
                type: "password",
                "input-label": $setup.$t("Current password"),
                modelValue: $setup.oldPassword,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.oldPassword) = $event))
              }, null, 8, ["input-label", "modelValue"])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_d_control_input, {
                rules: "required|max:128",
                name: "newPassword1",
                type: "password",
                "input-label": $setup.$t("New password"),
                modelValue: $setup.newPassword1,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.newPassword1) = $event))
              }, null, 8, ["input-label", "modelValue"])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_d_control_input, {
                rules: `required|max:128|confirmed:${$setup.newPassword1}`,
                name: "newPassword2",
                type: "password",
                "input-label": $setup.$t("Confirm new password"),
                modelValue: $setup.newPassword2,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.newPassword2) = $event))
              }, null, 8, ["rules", "input-label", "modelValue"])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("button", {
                class: _normalizeClass(["tt-button", {'disabled' : !$setup.buttonHandler() }]),
                type: 'submit'
              }, [
                _createElementVNode("p", _hoisted_11, _toDisplayString($setup.$t('Send')), 1)
              ], 2)
            ])
          ])
        ]),
        _: 1
      }, 512),
      _createElementVNode("div", _hoisted_12, [
        _createVNode($setup["vueRecaptcha"], {
          size: "invisible",
          sitekey: $setup.key,
          theme: $setup.colorSchemeCookie,
          onVerify: _cache[3] || (_cache[3] = ($event: any) => ($setup.recaptchaVerified($event, $setup.request))),
          ref: "recaptcha"
        }, null, 8, ["sitekey", "theme"])
      ])
    ])
  ]))
}