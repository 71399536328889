import { RouteRecordRaw } from 'vue-router';
import { route } from '@/router/utils'

const pageNotFound = () => import('./404.vue')

// NAMES
export const PAGE_NOT_FOUND_PAGE_NAME = 'cabinet:page-not-found'

// URLs
export const PAGE_NOT_FOUND_PAGE_URL = ''

export function createRoutes(): RouteRecordRaw[] {
  return [
    route(PAGE_NOT_FOUND_PAGE_URL, pageNotFound, PAGE_NOT_FOUND_PAGE_NAME, {
      meta: {
        breadcrumb: 'page-not-found', withoutBreadcrumbs: true,
      }
    }),
  ]
}