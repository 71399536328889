import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "g-row g-row--appearance_spaced g-row--space_md g-row--space_2xl-xl g-row--align_stretch g-row--justify_space-between" }
const _hoisted_2 = { class: "g-cell g-cols g-cols--12 g-cols--4-md g-cols--auto-xl" }
const _hoisted_3 = {
  key: 0,
  class: "filters-button"
}
const _hoisted_4 = { class: "tt-button__title" }
const _hoisted_5 = { class: "g-cell g-cols g-cols--12 g-cols--4-md g-cols--auto-xl order-first flex for_search ordered" }
const _hoisted_6 = { class: "filters-modal__wrapper" }
const _hoisted_7 = { class: "filters-modal__button" }
const _hoisted_8 = ["aria-label"]
const _hoisted_9 = { class: "filters-modal__title" }
const _hoisted_10 = { class: "filters-modal__content" }
const _hoisted_11 = { class: "g-row g-row--appearance_spaced g-row--space_md g-row--space_xl-xl g-row--align_stretch g-row--justify_space-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_notifications_types_filter = _resolveComponent("notifications-types-filter")!
  const _component_d_control_input = _resolveComponent("d-control-input")!
  const _component_i_filters = _resolveComponent("i-filters")!
  const _component_i_close = _resolveComponent("i-close")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.list, (field) => {
        return (_openBlock(), _createElementBlock(_Fragment, {
          key: field.id
        }, [
          (field.type == $setup.TABS_TYPE)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createElementVNode("div", {
                  class: _normalizeClass(["g-cell", 'g-cols--12 g-cols--8-md g-cols--10-xl'])
                }, [
                  _createVNode(_component_notifications_types_filter, _mergeProps({ ref_for: true }, $setup.options[field.type], {
                    list: field.props.items,
                    value: $setup.form[field.id],
                    "onUpdate:modelValue": ($event: any) => ($setup.getSearch($event, field, true))
                  }), null, 16, ["list", "value", "onUpdate:modelValue"])
                ]),
                _createElementVNode("div", _hoisted_2, [
                  _renderSlot(_ctx.$slots, "default")
                ])
              ], 64))
            : (field.type == $setup.SEARCH_TYPE)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["g-cell relative flex-row", 'g-cols--12 g-cols--8-md g-cols--10-xl order-first for_search'])
                  }, [
                    _createVNode(_component_d_control_input, _mergeProps({
                      "input-label": field.caption,
                      name: field.id,
                      options: field.props.items || [],
                      ref_for: true,
                      ref: field.id,
                      type: $setup.options[field.type].type,
                      placeholder: field.placeholder || ''
                    }, $setup.options[field.type], {
                      modelValue: $setup.form[field.id],
                      "onUpdate:modelValue": [($event: any) => (($setup.form[field.id]) = $event), ($event: any) => ($setup.getSearch(null, field, true))],
                      onRemove: ($event: any) => ($setup.getSearch(null, field, true))
                    }), null, 16, ["input-label", "name", "options", "type", "placeholder", "modelValue", "onUpdate:modelValue", "onRemove"]),
                    ($setup.list.length > 1)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                          _createElementVNode("button", {
                            class: "tt-button tt-button--light tt-button--square",
                            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => ($setup.toggleFiltersModal()), ["prevent"]))
                          }, [
                            _createElementVNode("span", _hoisted_4, [
                              _createVNode(_component_i_filters)
                            ])
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _renderSlot(_ctx.$slots, "default")
                  ])
                ], 64))
              : _createCommentVNode("", true)
        ], 64))
      }), 128))
    ]),
    (_openBlock(), _createBlock(_Teleport, {
      to: "#js-filters-modal",
      disabled: $setup.isDesktop
    }, [
      ($setup.isDesktop ? true : $setup.isShow)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "filters-modal",
            onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => ($setup.toggleFiltersModal()), ["self"]))
          }, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("button", {
                  class: "modal-close-button",
                  type: "button",
                  "aria-label": $setup.$t('Close'),
                  onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.toggleFiltersModal()))
                }, [
                  _createVNode(_component_i_close)
                ], 8, _hoisted_8)
              ]),
              _createElementVNode("div", _hoisted_9, _toDisplayString($setup.$t('Filters')), 1),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.list, (field) => {
                    return (_openBlock(), _createElementBlock(_Fragment, {
                      key: field.id
                    }, [
                      (field.type !== $setup.SEARCH_TYPE && field.type !== $setup.TABS_TYPE)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass(["g-cell relative", 'g-cols--12 g-cols--4-md'])
                          }, [
                            _createVNode(_component_d_control_input, _mergeProps({
                              "input-label": field.caption,
                              name: field.id,
                              options: field.props.items || [],
                              ref_for: true,
                              ref: field.id,
                              type: $setup.options[field.type].type,
                              placeholder: field.placeholder || ''
                            }, $setup.options[field.type], {
                              modelValue: $setup.form[field.id],
                              "onUpdate:modelValue": [($event: any) => (($setup.form[field.id]) = $event), ($event: any) => (field.type == 'multiselect' ? () => {} : $setup.getSearch(null, field))],
                              onSearchChange: $event => $setup.options[field.type].search($event, field),
                              onClose: ($event: any) => ($setup.getSearch(null, field)),
                              onRemove: ($event: any) => ($setup.getSearch(null, field))
                            }), null, 16, ["input-label", "name", "options", "type", "placeholder", "modelValue", "onUpdate:modelValue", "onSearchChange", "onClose", "onRemove"])
                          ]))
                        : _createCommentVNode("", true)
                    ], 64))
                  }), 128))
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ], 8, ["disabled"]))
  ], 64))
}