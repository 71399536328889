import {
  createSender,
  createReceiver,
  createUpdater,
  r
} from '@/resource/resource'

import {
  prefixAPI,
  API_BASE_URL,
} from '@/resource/api'

const MODEL = 'trash'

const TRASH_ATTACHMENTS_LIST_LINK = prefixAPI('/attachment/{?limit,offset,filters}', MODEL)
const TRASH_PROTOCOLS_LIST_LINK = prefixAPI('/protocols/{?limit,offset,filters}', MODEL)
const TRASH_TAGS_LIST_LINK = prefixAPI('/tags/{?limit,offset,filters}', MODEL)

const TRASH_PROTOCOLS_FILTERS_LIST_LINK = prefixAPI('/protocols/list/filters/', MODEL)


export const attachmentsTrashListResource = createReceiver(TRASH_ATTACHMENTS_LIST_LINK)
export const protolocsTrashListResource = createReceiver(TRASH_PROTOCOLS_LIST_LINK)
export const protolocsTrashFiltersResource = createReceiver(TRASH_PROTOCOLS_FILTERS_LIST_LINK)
export const tagsTrashListResource = createReceiver(TRASH_TAGS_LIST_LINK)

