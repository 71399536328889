import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "g-row g-row--appearance_spaced g-row--space_2xl g-row--align_start" }
const _hoisted_2 = { class: "g-cell g-cols g-cols--auto" }
const _hoisted_3 = { class: "g-cell g-cols g-cols--12" }
const _hoisted_4 = { class: "g-row g-row--appearance_spaced g-row--space_2xl" }
const _hoisted_5 = { class: "g-cell g-cols g-cols--12" }
const _hoisted_6 = { class: "ds-table-wrapper" }
const _hoisted_7 = { class: "g-cell g-cols g-cols--auto" }
const _hoisted_8 = { class: "tt-pagination" }
const _hoisted_9 = { class: "tt-pagination__pages-info" }
const _hoisted_10 = { class: "tt-pagination__info-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loader = _resolveComponent("loader")!
  const _component_filters_view = _resolveComponent("filters-view")!
  const _component_ui_table = _resolveComponent("ui-table")!
  const _component_ui_limit_pagination = _resolveComponent("ui-limit-pagination")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.load)
      ? (_openBlock(), _createBlock(_component_loader, {
          key: 0,
          class: "full"
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_filters_view, {
          list: $setup.filtersList,
          onUpdate: $setup.setFiltersValues
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default")
          ]),
          _: 3
        }, 8, ["list"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_ui_table, {
                "table-fields": $setup.props.tableFields,
                items: $setup.tableValues
              }, {
                "table-body-row-actions": _withCtx((data) => [
                  _renderSlot(_ctx.$slots, "table-body-row-actions", _normalizeProps(_guardReactiveProps(data)))
                ]),
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.props.tableFields, (field) => {
                    return (_openBlock(), _createElementBlock(_Fragment, null, [
                      _createElementVNode("pre", null, _toDisplayString(field) + " @@@@@", 1),
                      _renderSlot(_ctx.$slots, $setup.getSlotName(field.key), _mergeProps({ ref_for: true }, _ctx.data))
                    ], 64))
                  }), 256))
                ]),
                _: 3
              }, 8, ["table-fields", "items"])
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_ui_limit_pagination, {
                pagination: $setup.paginations,
                onInput: $setup.handlePaginate
              }, {
                default: _withCtx(() => [
                  _createElementVNode("p", _hoisted_9, [
                    _createElementVNode("span", _hoisted_10, _toDisplayString($setup.getPaginateInfo($setup.paginations)), 1),
                    _createElementVNode("span", {
                      class: "tt-pagination__info-button",
                      onClick: $setup.showAll
                    }, _toDisplayString($setup.$t('Show all')), 1)
                  ])
                ]),
                _: 1
              }, 8, ["pagination"])
            ])
          ])
        ])
      ])
    ])
  ], 64))
}