import { App } from 'vue'

import { Form, Field, ErrorMessage, defineRule } from './index'
import {
  required,
  email,
  confirmed,
  postBackUrl,
  maxValue,
  minValue,
  min,
  max,
  monthYear,
  phone,
  numbers,
  float,
} from './rules'

export default {
  install(app: App<Element>) {
    app.component('VeeForm', Form)
    app.component('VeeField', Field)
    app.component('VeeError', ErrorMessage)
    defineRule('required', required.bind(app))
    defineRule('email', email.bind(app))
    defineRule('phone', phone.bind(app))
    defineRule('float', float.bind(app))
    defineRule('numbers', numbers.bind(app))
    defineRule('monthYear', monthYear.bind(app))
    defineRule('confirmed', confirmed.bind(app))
    defineRule('minValue', minValue.bind(app))
    defineRule('min', min.bind(app))
    defineRule('max', max.bind(app))
    defineRule('maxValue', maxValue.bind(app))
    defineRule('postBackUrl', postBackUrl.bind(app))
  }
}
