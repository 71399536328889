import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "cabinet-sidebar-menu-nested__item" }
const _hoisted_2 = {
  key: 0,
  class: "cabinet-sidebar-menu-nested__icon"
}
const _hoisted_3 = ["label"]
const _hoisted_4 = {
  key: 2,
  class: "cabinet-sidebar-menu-nested__counter-wrapper"
}
const _hoisted_5 = { class: "cabinet-sidebar-menu-nested__counter" }
const _hoisted_6 = {
  key: 0,
  class: "cabinet-sidebar-menu-nested__nested"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cabinet_sidebar_menu_link = _resolveComponent("cabinet-sidebar-menu-link")!

  return (_openBlock(), _createElementBlock("ul", {
    class: _normalizeClass(["cabinet-sidebar-menu-nested", { 'is-condensed': $props.condensed, [`is-level_${$props.level}`]: !!$props.level }])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.items, (item) => {
      return (_openBlock(), _createElementBlock("li", _hoisted_1, [
        _createVNode(_component_cabinet_sidebar_menu_link, {
          class: "cabinet-sidebar-menu-nested__link",
          href: item.href,
          to: item.to
        }, {
          default: _withCtx(() => [
            (_ctx.$slots.icon || item.icon)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
                  _renderSlot(_ctx.$slots, "icon", {
                    icon: item.icon
                  }, () => [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon), { size: "lg" }))
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.$slots.label || item.label)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 1,
                  class: "cabinet-sidebar-menu-nested__label",
                  label: item.label
                }, [
                  _renderSlot(_ctx.$slots, "label", {
                    label: item.label
                  }, () => [
                    _createTextVNode(_toDisplayString(item.label), 1)
                  ])
                ], 8, _hoisted_3))
              : _createCommentVNode("", true),
            (_ctx.$slots.counter || item?.counter)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("span", null, [
                      _renderSlot(_ctx.$slots, "counter", {
                        counter: item?.counter
                      }, () => [
                        _createTextVNode(_toDisplayString(item?.counter), 1)
                      ])
                    ])
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1032, ["href", "to"]),
        ($setup.props.depth && $setup.props.depth >= $setup.props.level && item.children && $setup.props.nestedComponent)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _renderSlot(_ctx.$slots, "nested", {}, () => [
                (_openBlock(), _createBlock(_resolveDynamicComponent($props.nestedComponent), {
                  items: item.children,
                  condensed: $props.condensed,
                  nestedComponent: $props.nestedComponent,
                  level: $props.level + 1
                }, null, 8, ["items", "condensed", "nestedComponent", "level"]))
              ])
            ]))
          : _createCommentVNode("", true)
      ]))
    }), 256))
  ], 2))
}