<template>
<component :is="as" :class="classes.root.value">
  <div :class="classes.controls.value" v-if="controls && src">
    <div :class="classes.control.value" v-if="controls.clear">
      <slot name="cancel" :cancel="clear">
        <ControlButton @click="clear()" class="up-crop-ui-button--delete" appearance="square" type="button">
          <ITrashImage />
        </ControlButton>
      </slot>
    </div>
  </div>
  <input v-bind="inputProps" />
  <div :class="classes.inner.value" v-bind="rootProps">
    <div :class="classes['preview-wrapper'].value" v-if="src">
      <img :class="classes.preview.value" :src="src" />
    </div>
    <div :class="classes.placeholder.value" v-if="!src">
      <slot name="placeholder">
        <img :src="placeholderSrc" :class="classes['placeholder-image'].value" v-if="placeholderSrc" />
        <div :class="classes['placeholder-label'].value" v-if="$slots.uplabel">
          <slot name="label">{{ label }}</slot>
        </div>
      </slot>
    </div>
  </div>
  <vue-final-modal v-model="opened" :esc-to-close="true">
    <up-crop-modal
      :src="file.value"
      :with-ui="true"
      :format="format"
      @close="closeModal"
      @save="onSave"
      v-bind="cropAttributes"
      v-if="opened"
    >
      <!-- Passing slots inside. -->

      <template v-for="(_, sn) in $slots" v-slot:[sn]="sd">
        <slot :name="sn" v-bind="sd" />
      </template>
    </up-crop-modal>
  </vue-final-modal>
</component>

</template>

<script>

import { ref, computed }  from 'vue'
import { extendComponent } from '@aspectus/vue-bem-classes'
import { VueFinalModal } from 'vue-final-modal'
import { useDropzone } from 'vue3-dropzone'
import multiproxy from '@aspectus/multiproxy'
import useGlobal from '@/composables/useGlobal'
import ControlButton from './Button.vue'
import { toBase64 } from '../utils'
import { EXTENSIONS_MAP } from '../const'
import { useConfirmDialog } from '@/composables/useConfirm'

const [useClasses, Component] = extendComponent('up-image', {
  emits: ['save', 'sizeError'],
  props: {
    src: {},
    label: { type: String },
    controls: { type: Object, default: () => ({ clear: true }) },
    placeholderSrc: { type: String },
    format: { type: String, default: 'image/png' },
    accept: { type: [String, Array], default: () => (['image/*']) },
    dropOptions: { type: Object, default: () => ({}) },
  },
  components: { ControlButton, VueFinalModal },
  setup(props, { emit, attrs }) {
    const { $t } = useGlobal()
    const errorSizeMessage = $t('The file size should not exceed 5mb.')
    const proxiedProps = multiproxy(props)
    const classes = useClasses(proxiedProps)
    const opened = ref(false)
    const maxFileSize = 10
    const formatDivider = 1e+6
    const file = ref({ name: null, value: null })
    const closeModal = () => { opened.value = false }

    function onDrop(accepted) {
      if(maxFileSize < accepted[0].size / formatDivider) {
        emit('sizeError', errorSizeMessage)
        return
      }
      if (accepted.length == 1) {
        toBase64(accepted[0]).then(base64 => {
          // Storing filename to use it for a created blob.
          file.value = {
            name: `${accepted[0].name}${EXTENSIONS_MAP[proxiedProps.format] || ''}`,
            value: base64
          }
          opened.value = true
        })
      }
    }

    function onSave(result) {
      result.name = file.value.name
      result.path = result.name
      emit('save', result)
    }

    const { getRootProps, getInputProps, open: upload } = useDropzone({
      ...proxiedProps.dropOptions,
      accept: proxiedProps.accept,
      onDrop,
      multiple: false,
    })

    const rootProps = computed(() => ({ ...attrs, class: undefined, style: undefined, ...getRootProps() }))
    const inputProps = computed(() => getInputProps())
    const cropAttributes = computed(() => ({ ...attrs, class: undefined, style: undefined, }))
    
    const clear = async () => {
      try {
        const question = $t('Are you sure you want to remove?')
        await useConfirmDialog({question})
        emit('save', null)
      } catch (e) {
        console.error(e)
      }
    }
    return {
      classes,
      rootProps,
      inputProps,
      upload,
      opened,
      onSave,
      file,
      clear,
      closeModal,
      cropAttributes,
    }
  }
}, [
  'inner',
  'controls', 'control',
  'modal',
  'preview-wrapper', 'preview',
  'placeholder', 'placeholder-image', 'placeholder-label'
])

export default Component

</script>

<style lang="sass">

.up-image
  display: flex
  position: relative

  &__
    // &preview-wrapper,
    // &inner,
    // &preview
    //   max-width: 100%
    //   max-height: 100%

    // &inner,
    &preview,
    &preview-wrapper
      width: 100%
      height: 100%

    &preview
      object-fit: contain

    &inner
      // background: var(--theme-relief-2)
      overflow: hidden
      border-radius: var(--br-size)
      cursor: pointer
      flex-grow: 1
      display: flex
      align-items: center
      justify-content: center

    // &preview-wrapper
    //   flex: 1 1 0
    //   display: flex
    //   height: 100%

    &placeholder
      // padding: var(--space-3)
      // margin: var(--space-2)
      display: flex
      flex-direction: column
      align-items: center
      border-radius: var(--br-size)
      flex-shrink: 0

    &placeholder-label
      display: block
      // margin-top: var(--space-2)
      font-size: var(--type-font-size-xs)

    &placeholder-image
      max-width: 100%
      max-height: 100%

    &controls
      position: absolute
      right: 0
      top: 0
      // transform: translateX(-50%)
      display: flex
      flex-wrap: no-wrap

    &control
      margin: var(--space-1)
      & path
        stroke: var(--theme-relief-1)

</style>
