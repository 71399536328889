<template>

<component :is="as" :class="classes.root.value" v-bind="$attrs">
  <div :class="classes.inner.value">
    <slot :classes="classes">
      <vue-cropper
        :class="classes.cropper.value"
        ref="cropper"
        :src="src"
        :transitions="true"
        image-restriction="fit-area"
        @ready="ready"
        v-bind="options"
      />
    </slot>
  </div>
  <div :class="classes.controls.value" v-if="(controls || $slots.controls) && !loading">
    <slot name="controls" :classes="classes">
      <div :class="classes.control.value" v-if="controls.flipH">
        <ControlButton @click="flip(true, false)" appearance="square" type="button">
          <IFlipHorizontal />
        </ControlButton>
      </div>
      <div :class="classes.control.value" v-if="controls.flipV">
        <ControlButton @click="flip(false, true)" appearance="square" type="button">
          <IFlipVertical />
        </ControlButton>
      </div>
      <div :class="classes.control.value" v-if="controls.rotateClock">
        <ControlButton @click="rotate(90)" appearance="square" type="button">
          <IRotateClockwise />
        </ControlButton>
      </div>
      <div :class="classes.control.value" v-if="controls.rotateNonClock">
        <ControlButton @click="rotate(-90)" appearance="square" type="button">
          <IRotateCounterClockwise />
        </ControlButton>
      </div>
    </slot>
  </div>
  <ControlButton @click="save" styling="highlight" :class="classes.save.value" v-if="!loading">
    <slot name="save" :classes="classes">
      {{ $t('Save image') }}
    </slot>
  </ControlButton>
</component>

</template>

<script>
import { ref, watch } from 'vue'

import { extendComponent } from '@aspectus/vue-bem-classes'
import multiproxy from '@aspectus/multiproxy'

import ControlButton from './Button.vue'
import IRotateClockwise from './icons/RotateClockwise.vue'
import IRotateCounterClockwise from './icons/RotateCounterClockwise.vue'
import IFlipHorizontal from './icons/FlipHorizontal.vue'
import IFlipVertical from './icons/FlipVertical.vue'
import { useCropperControl } from '../controller'

const [useClasses, Component] = extendComponent('up-crop', {
  props: {
    controls: { type: Object, default: () => ({
      flipH: true, flipV: true, rotateClock: true, rotateNonClock: true,
    }) },
    format: { type: String, default: 'base64' },
    options: { type: Object, default: () => ({}) },
    stencilProps: { type: Object, default: () => ({}) },
    src: {},
  },
  emits: ['save', 'result'],
  components: {
    ControlButton,
    IRotateClockwise,
    IRotateCounterClockwise,
    IFlipHorizontal,
    IFlipVertical,
  },
  setup(props, { emit }) {
    const proxiedProps = multiproxy(props)
    const classes = useClasses(proxiedProps)
    const { cropper, flip, rotate, result } = useCropperControl()
    const loading = ref(true)
    function save() {
      const r = result()

      emit('result', r)

      if (proxiedProps.format === 'base64') {
        emit('save', r.canvas.toDataURL())
      } else {
        r.canvas.toBlob(blob => emit('save', blob), proxiedProps.format)
      }
    }

    watch(() => proxiedProps.src, () => {
      loading.value = true
    })

    function ready() {
      loading.value = false
    }

    return {
      loading,
      classes,
      cropper,
      flip,
      rotate,
      save,
      ready,
    }
  }
}, ['inner', 'save', 'controls', 'control', 'cropper'])
export default Component

</script>

<style lang="sass">

.up-crop
  position: relative
  user-select: none
  display: flex
  background: black

  &__
    &inner
      position: relative
      max-width: 100%
      display: flex
      flex-grow: 1

    &controls
      position: absolute
      left: 0
      top: 50%
      transform: translateY(-50%)

    &cropper
      flex-grow: 1

    &control
      margin: var(--space-1)

    &save
      position: absolute
      bottom: 0
      left: 50%
      transform: translateX(-50%)

</style>