import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "ui-dropdown__icon ui-dropdown__icon--lang" }
const _hoisted_3 = {
  key: 0,
  class: "ui-dropdown__single"
}
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "ui-dropdown__content ui-dropdown__content--language" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i_lang = _resolveComponent("i-lang")!
  const _component_i_arrow_down_thin = _resolveComponent("i-arrow-down-thin")!
  const _component_ui_click_outside = _resolveComponent("ui-click-outside")!
  const _component_ui_dropdown = _resolveComponent("ui-dropdown")!

  return (_openBlock(), _createBlock(_component_ui_dropdown, null, {
    default: _withCtx(({ isActive, toggle, hide }) => [
      _createVNode(_component_ui_click_outside, { do: hide }, {
        default: _withCtx(({ ref }) => [
          _createElementVNode("div", {
            class: "ui-dropdown",
            ref: ref
          }, [
            _createElementVNode("div", null, [
              _createElementVNode("div", {
                class: "ui-dropdown__element",
                onClick: _withModifiers(toggle, ["prevent"])
              }, [
                _createElementVNode("span", _hoisted_2, [
                  _createVNode(_component_i_lang)
                ]),
                ($setup.currentItem)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString($setup.currentItem.display), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("span", {
                  class: _normalizeClass(["ui-dropdown__icon ui-dropdown__icon--arrow", { 'is-active': isActive }])
                }, [
                  _createVNode(_component_i_arrow_down_thin)
                ], 2)
              ], 8, _hoisted_1),
              _createElementVNode("div", {
                class: _normalizeClass(["ui-dropdown__wrapper", { 'is-active': isActive }])
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.filteredItems, (item) => {
                  return (_openBlock(), _createElementBlock("a", {
                    class: "ui-dropdown__item",
                    href: $setup.getLanguageLink(item.value.toLowerCase())
                  }, [
                    _createElementVNode("span", _hoisted_5, _toDisplayString(item.display), 1)
                  ], 8, _hoisted_4))
                }), 256))
              ], 2)
            ])
          ], 512)
        ]),
        _: 2
      }, 1032, ["do"])
    ]),
    _: 1
  }))
}