import { App, defineAsyncComponent } from 'vue';
// import VueGoogleMaps from '@fawmi/vue-google-maps';
import { vIntersectionObserver, vOnClickOutside } from '@vueuse/components';
import { tableWrapper } from './directives/table'
import {
  // Directives
  vTooltip,
  // Components
  Dropdown,
} from 'floating-vue'
import VueDatePicker from 'vue-datepicker-next';
import VueSlider from 'vue-slider-component'
import VueRouterPermission from '@/packages/vue-router-permissions'
import { AllSelectionController } from './composables/all-selector-controller';
import { VueDraggable } from 'vue-draggable-plus'

import 'vue-datepicker-next/index.css';

export default {
  install(app: App<Element>): void {
    app
      .directive('on-click-outside', vOnClickOutside)
      .directive('intersection-observer', vIntersectionObserver)
      .directive('table-wrapper', tableWrapper)
    app
      // .use(VueGoogleMaps, {
      //   load: {
      //     key: window.CONFIG.GOOGLE_MAPS_KEY || '',
      //     language: window.language,
      //   },
      // })
      .component('VueDraggable', VueDraggable)
      .component('AllSelectionController', AllSelectionController)
      .component('VDropdown', Dropdown)
      .component('DatePicker', VueDatePicker)
      .component('VueSlider', VueSlider)
      .use(VueRouterPermission)
      .directive('tooltip', vTooltip)
      
  },
}
