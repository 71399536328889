import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withModifiers as _withModifiers, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "g-row g-row--align_center g-row--justify_center relative" }
const _hoisted_2 = {
  key: 0,
  class: "g-cell g-cols g-cols--auto"
}
const _hoisted_3 = { class: "ds-link ds-link--appearance_block" }
const _hoisted_4 = ["alt"]
const _hoisted_5 = { class: "g-cell g-cols" }
const _hoisted_6 = ["aria-label"]
const _hoisted_7 = ["href"]
const _hoisted_8 = { class: "store-button__text" }
const _hoisted_9 = { class: "cabinet-sidebar-menu-section cabinet-sidebar-menu-section--last" }
const _hoisted_10 = { class: "cabinet-sidebar-menu-element__icon" }
const _hoisted_11 = { class: "cabinet-sidebar-menu-element__label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i_sidebar_trigger = _resolveComponent("i-sidebar-trigger")!
  const _component_i_sidebar_trigger_close = _resolveComponent("i-sidebar-trigger-close")!
  const _component_i_close = _resolveComponent("i-close")!
  const _component_i_store = _resolveComponent("i-store")!
  const _component_i_logout = _resolveComponent("i-logout")!
  const _component_CabinetSidebarMenu = _resolveComponent("CabinetSidebarMenu")!
  const _component_CabinetSidebar = _resolveComponent("CabinetSidebar")!

  return (_openBlock(), _createBlock(_component_CabinetSidebar, {
    condensed: $props.condensed && $props.condensable,
    opened: !$props.condensable && $props.opened || false
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_Transition, { name: "logo" }, {
          default: _withCtx(() => [
            (!($props.condensed && $props.condensable))
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("img", {
                      class: "logo logo--variant_cabinet",
                      src: "/static/app/tt-light-logo.png",
                      alt: $setup.$t('Logo cabinet alt')
                    }, null, 8, _hoisted_4)
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("button", {
            class: "cabinet-sidebar__drawer-receiver",
            onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => ($props.toggle && $props.toggle(...args)), ["prevent"]))
          }, [
            ($props.condensable && !$props.condensed)
              ? (_openBlock(), _createBlock(_component_i_sidebar_trigger, {
                  key: "i-sidebar-collapse",
                  size: "xl",
                  name: "i-sidebar-collapse"
                }))
              : _createCommentVNode("", true),
            ($props.condensable && $props.condensed)
              ? (_openBlock(), _createBlock(_component_i_sidebar_trigger_close, {
                  key: "i-sidebar-expand",
                  size: "xl",
                  name: "i-sidebar-expand"
                }))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _createElementVNode("button", {
        class: "cabinet-sidebar__close-button",
        type: "button",
        "aria-label": $setup.$t('Close'),
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($props.toggle && $props.toggle(...args)))
      }, [
        _createVNode(_component_i_close, {
          "aria-hidden": "true",
          name: "i-close",
          size: "lg"
        })
      ], 8, _hoisted_6)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_CabinetSidebarMenu, {
        sections: $setup.navigation.sections,
        condensed: $props.condensed && $props.condensable,
        opened: false
      }, {
        prepend: _withCtx(() => [
          _createElementVNode("a", {
            class: "store-button",
            href: $setup.shopLink
          }, [
            _createElementVNode("span", _hoisted_8, _toDisplayString($setup.$t("To the store")), 1),
            _createVNode(_component_i_store, { class: "store-button__icon" })
          ], 8, _hoisted_7)
        ]),
        append: _withCtx(() => [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", {
              class: _normalizeClass(["cabinet-sidebar-menu-element", { 'is-condensed': $props.condensed && $props.condensable }]),
              ref: "logoutMenuItemRef",
              onMouseover: _cache[3] || (_cache[3] = ($event: any) => ($setup.openCondensedMenuItem($setup.logoutMenuItemRef))),
              onMouseleave: _cache[4] || (_cache[4] = ($event: any) => ($setup.closeCondensedMenuItem($setup.logoutMenuItemRef)))
            }, [
              _createElementVNode("a", {
                class: "cabinet-sidebar-menu-element__link cabinet-sidebar-menu-link",
                type: "button",
                onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => ($setup.logout()), ["prevent"]))
              }, [
                _createElementVNode("span", _hoisted_10, [
                  _createVNode(_component_i_logout, {
                    size: "md",
                    name: 'i-logout'
                  })
                ]),
                _createElementVNode("span", _hoisted_11, _toDisplayString($setup.$t("Log out")), 1)
              ])
            ], 34)
          ])
        ]),
        _: 1
      }, 8, ["sections", "condensed"])
    ]),
    _: 1
  }, 8, ["condensed", "opened"]))
}