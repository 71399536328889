interface Field {
  name: string;
  label: string;
  rules: { [key: string]: any } | string;
  component?: any; 
  [key: string]: any; 
  isRequired?: boolean;
}

const REQUIRED_RULE = 'required'
const REQUIRED = { [REQUIRED_RULE]: true }

const isRequired = (rules) => {
  if (typeof rules === 'string') {
    return rules.includes(REQUIRED_RULE)
  }
  return REQUIRED_RULE in rules
}

export default function useField(
  name: string,
  label: string,
  { rules = REQUIRED, component, ...rest }: Partial<Field> = {}
): Field {
  return { name, label, rules, component, isRequired: isRequired(rules), ...rest };
}
