import {
  createSender,
  createReceiver,
  createUpdater,
  r
} from '@/resource/resource'

import {
  prefixAPI,
} from '@/resource/api'

const MODEL = 'protocol'

const PROTOCOLS_LIST_LINK = prefixAPI('/list/{?limit,offset,filters}', MODEL)
const PROTOCOLS_FILTERS_LIST_LINK = prefixAPI('/list/filters/', MODEL)
const PROTOCOLS_VISIBILITY_LINK = prefixAPI('/visibility-toggle/', MODEL)
const PROTOCOLS_TRASH_LINK = prefixAPI('/trash/{?filters}', MODEL)
const PROTOCOLS_RESTORE_LINK = prefixAPI('/activate/', MODEL)
const PROTOCOLS_SORTING_LINK = prefixAPI('/sorting/', MODEL)
const PROTOCOLS_BULK_SORTING_LINK = prefixAPI('/bulk-sorting/{?limit,offset,filters}', MODEL)
const PROTOCOLS_ARCHIVE_LINK = prefixAPI('/archive/', MODEL)
const PROTOCOL_DETAILS_LINK = prefixAPI('/{id}/', MODEL)
const PROTOCOL_CREATE_LINK = prefixAPI('/create/', MODEL)
const PROTOCOL_UPDATE_LINK = prefixAPI('/update/{id}/', MODEL)
const PROTOCOL_MODERATE_LINK = prefixAPI('/set-moderation-status/{id}/', MODEL)

export const protocolsListResource = createReceiver(PROTOCOLS_LIST_LINK)
export const protocolDetailsResource = createReceiver(PROTOCOL_DETAILS_LINK)
export const protocolCreateResource = createSender(PROTOCOL_CREATE_LINK)
export const protocolSortingResource = createSender(PROTOCOLS_SORTING_LINK)
export const protocolBulkSortingResource = createSender(PROTOCOLS_BULK_SORTING_LINK)
export const protocolModerateResource = createReceiver(PROTOCOL_MODERATE_LINK)
export const protocolTrashResource = createSender(PROTOCOLS_TRASH_LINK)
export const protocolRestoreResource = createSender(PROTOCOLS_RESTORE_LINK)
export const protocolArchiveResource = createSender(PROTOCOLS_ARCHIVE_LINK)
export const protocolVisibilityToogleResource = createSender(PROTOCOLS_VISIBILITY_LINK)
export const protocolUpdateResource = createUpdater(PROTOCOL_UPDATE_LINK)
export const protocolFiltersResource = createReceiver(PROTOCOLS_FILTERS_LIST_LINK)