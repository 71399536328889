import { App } from 'vue'

import Drawer from './drawer'

import Validation from './validation/plugin'
import Uploading from './uploading/plugin'
import FlatPickr from './vue3-flatpickr'
import VueFinalModal from './vue-final-modal'
import {
  Sidebar,
  SidebarMenu,
  SidebarMenuElement,
  SidebarMenuLink,
  SidebarMenuNested,
  SidebarMenuSection,
} from './sidebar';

export default {
  install(app: App<Element>, { prefix = 'Cabinet'} = {}): void {
    app
      .use(Drawer)
      .use(VueFinalModal)
      .use(Validation)
      .use(FlatPickr)
      .use(Uploading)
      .component(`${prefix}Sidebar`, Sidebar)
      .component(`${prefix}SidebarMenu`, SidebarMenu)
      .component(`${prefix}SidebarMenuElement`, SidebarMenuElement)
      .component(`${prefix}SidebarMenuLink`, SidebarMenuLink)
      .component(`${prefix}SidebarMenuSection`, SidebarMenuSection)
      .component(`${prefix}SidebarMenuNested`, SidebarMenuNested)
  }
}
