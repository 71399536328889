import type { App } from 'vue'
import { defineAsyncComponent } from 'vue'

const DrawerContainer = defineAsyncComponent(() => import('./DrawerLocalStorageControllerComponent.vue'))
const DrawerReceiver =defineAsyncComponent(() => import('./DrawerReceiver'))
 


export default {
  install(app: App<Element>): void {
    app
      .component('DrawerContainer', DrawerContainer)
      .component('DrawerReceiver', DrawerReceiver)
  }
}