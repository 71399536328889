import { App } from 'vue'
import { accessMeta } from '@/packages/vue-router-permissions'
import { route, prefixRoutes } from '@/router/utils'
import { RouteRecordRaw } from 'vue-router'
import { createRoutes as createProtocolsRoutes, PROTOCOLS_LIST_PAGE_NAME } from './modules/protocols/router'
import { createRoutes as createProtocolsTrashRoutes, PROTOCOLS_TRASH_LIST_PAGE_NAME } from './modules/trash-protocols/router'
import { createRoutes as createPageNotFoundRoutes, PAGE_NOT_FOUND_PAGE_NAME } from './modules/404/router'
import { createRoutes as createAccessDeniedRoutes } from './modules/403/router'
import { createRoutes as createTagsRoutes } from './modules/tags/router'
import { createRoutes as createTagsTrashRoutes } from './modules/trash-tags/router'
import { createRoutes as createSourcesRoutes } from './modules/sources/router'
import { createRoutes as createQuestionsRoutes, QUESTIONS_LIST_PAGE_NAME } from './modules/questions/router'
import { createRoutes as createAttachmentsRoutes } from './modules/attachments/router'
import { createRoutes as createTrashAttachmentsRoutes } from './modules/trash-attachments/router'
import { createRoutes as createProfileRoutes } from './modules/profile/router'
import { createRoutes as createNotificationsRoutes } from './modules/notifications//router'
import { allPermissions } from '@access/index'
import { accessPermission, } from '@/permissions/permissions'
import { prefixLanguage } from '@utils/urls'
import { checkPermissions } from "@/permissions/permissions";
// const textPage = () => import('./modules/text-page/text-page.vue')
const Cabinet = () => import('./views/Cabinet.vue')
const Base = () => import('./views/Base.vue')

const {
  STAFF: {
    PROTOCOL: {
      VIEW: VIEW_PROTOCOL,
      SORT: SORT_PROTOCOL
    },
    PROTOCOL_SOURCE: {
      VIEW: VIEW_PROTOCOL_SOURCE
    },
    TAG: {
      VIEW: VIEW_TAG,
      SORT: SORT_TAG
    },
    PROTOCOL_ATTACHMENT: {
      VIEW: VIEW_ATTACHMENT
    },
    PROTOCOL_QUESTION: {
      VIEW: VIEW_QUESTION
    },
    TRASH: {
      VIEW_PROTOCOL: VIEW_PROTOCOL_TRASH,
      VIEW_PROTOCOL_ATTACHMENT: VIEW_PROTOCOL_ATTACHMENT_TRASH,
      VIEW_TAG: VIEW_TAG_TRASH,
      VIEW_USER: VIEW_USER_TRASH
    },
    USER: {
      VIEW: VIEW_USER
    }
  },
} = allPermissions

export const BASE_PAGE_NAME = 'cabinet:page'
export const BASE_PAGE_URL = prefixLanguage('/cabinet/')

export const BASE_PROCEDURES_PAGE_NAME = 'cabinet:procedures'
export const BASE_TRASH_PAGE_NAME = 'cabinet:trash'
export const BASE_CONVERSATIONS_PAGE_NAME = 'cabinet:conversations'

export function createRoutes(options): RouteRecordRaw[]  {
  return [
    route(BASE_PAGE_URL, Cabinet, BASE_PAGE_NAME, {
      children: [
        prefixRoutes('procedures/', [
          prefixRoutes('protocols/', createProtocolsRoutes(),
            { 
              meta: { ...accessPermission([VIEW_PROTOCOL, ], options), 
              sort: checkPermissions([SORT_PROTOCOL]), 
              withLink: true,
              
            }}
          ),
          prefixRoutes('sources/', createSourcesRoutes(),
          { meta: { ...accessPermission([VIEW_PROTOCOL_SOURCE, ], options), withLink: true } }
          ),
          prefixRoutes('files/', createAttachmentsRoutes(),
          { meta: { ...accessPermission([VIEW_ATTACHMENT, ], options), withLink: true } }
          ),
          prefixRoutes('tags/', createTagsRoutes(options),
          { meta: { ...accessPermission([VIEW_TAG, ], options), sort: checkPermissions([SORT_TAG]), withLink: true } }
          ),
        ], {
          meta: { withLink: false },
          name: BASE_PROCEDURES_PAGE_NAME,
          redirect: { name: PROTOCOLS_LIST_PAGE_NAME } 
        }),
        prefixRoutes('conversations/', [
          prefixRoutes('protocols/', createQuestionsRoutes(),
          { 
            meta: { ...accessPermission([VIEW_QUESTION, ], options),
            withLink: true,
            
          }}),
        ], { 
          meta: { withLink: false },
          name: BASE_CONVERSATIONS_PAGE_NAME,
          redirect: { name: QUESTIONS_LIST_PAGE_NAME } 
        }),
        prefixRoutes('trash/', [
          prefixRoutes('protocols/', createProtocolsTrashRoutes(),
          { meta: { ...accessPermission([VIEW_PROTOCOL_TRASH, ], options), sort: false, trash: true, withLink: true } }
          ),
          prefixRoutes('files/', createTrashAttachmentsRoutes(),
            { meta: { ...accessPermission([VIEW_PROTOCOL_ATTACHMENT_TRASH, ], options), trash: true, withLink: true } }
          ),
          prefixRoutes('tags/', createTagsTrashRoutes(),
            { meta: { ...accessPermission([VIEW_TAG_TRASH, ], options), sort: false, trash: true, withLink: true } }
          ),
        ], { 
          meta: { withLink: false },
          name: BASE_TRASH_PAGE_NAME,
          redirect: { name: PROTOCOLS_TRASH_LIST_PAGE_NAME } 

        }),

        prefixRoutes('profile/', createProfileRoutes(options),
          { meta: { ...accessPermission([VIEW_USER, ], options) } }
        ),
        prefixRoutes('notifications/', createNotificationsRoutes(options),
          { meta: { ...accessPermission([VIEW_USER, ], options) } }
        ),
        prefixRoutes('not-found/', createPageNotFoundRoutes()),
        prefixRoutes('forbidden/', createAccessDeniedRoutes()),
        {
          path: prefixLanguage('/cabinet/:pathMatch(.*)*'),
          redirect: { name: PAGE_NOT_FOUND_PAGE_NAME }
        },
        // route('info/:slug/', textPage, 'text-page', {}),
      ],
      redirect: { name: PROTOCOLS_LIST_PAGE_NAME },
    }),
  ]
}
