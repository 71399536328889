import {
  createReceiver,
  createUpdater,
} from '@/resource/resource'

import {
  prefixAPI,
} from '@/resource/api'

const MODEL = 'account'

const PERSONAL_DATA_LINK = prefixAPI('/personal-data/', MODEL)
const PERSONAL_DATA_UPDATE_LINK = prefixAPI('/personal-data-edit/', MODEL)

export const personalDataResource = createReceiver(PERSONAL_DATA_LINK)
export const personalDataUpdateResource = createUpdater(PERSONAL_DATA_UPDATE_LINK)
