import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tt-image-loader-label-wrap" }
const _hoisted_2 = { class: "tt-image-loader-label" }
const _hoisted_3 = { class: "tt-image-loader-label__content" }
const _hoisted_4 = { class: "tt-image-loader-label__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(), _createBlock(_resolveDynamicComponent($props.icon), { class: "tt-image-loader-label__img" })),
        _createElementVNode("p", _hoisted_4, _toDisplayString($props.text || _ctx.$t('Upload image')), 1)
      ])
    ])
  ]))
}