export const TT_ACCESS_TOKEN_STORAGE_NAME = 'LS:ACCESS_TOKEN_STORAGE'
export const TT_REFRESH_TOKEN_STORAGE_NAME = 'LS:REFRESH_TOKEN_STORAGE'
export const TT_DEVICE_UUID_STORAGE_NAME = 'LS:DEVICE_UUID_STORAGE'

export const TT_LANGUAGE_COOKIE_NAME = 'LS:CHOSEN_LANGUAGE'

export const TT_CURRENT_PAYMENT_ID_NAME = 'LS:CURRENT_PAYMENT_ID'
export const TT_CURRENT_PAYMENT_URL_NAME = 'LS:CURRENT_PAYMENT_URL'

export const TT_USER_SAVED_NAME = 'LS:USER_SAVED_EMAIL'
export const TT_IS_AUTH_STORAGE_NAME = 'LS:IS_AUTH_STORAGE_NAME'

export const TT_WAREHOUSE_ID_STORAGE_NAME = 'LS:WAREHOUSE_ID'
export const TT_WAREHOUSE_TYPE_STORAGE_NAME = 'LS:WAREHOUSE_TYPE'

export const TT_COLOR_SCHEME_COOKIE_NAME = 'LS:COLOR_SCHEME_COOKIE_NAME'

export const shopLink = window.SHOP_LINK