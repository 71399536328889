import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = { class: "tt-cabinet relative" }
const _hoisted_2 = { class: "tt-cabinet__sidebar" }
const _hoisted_3 = { class: "tt-cabinet__main" }
const _hoisted_4 = { class: "tt-cabinet__header" }
const _hoisted_5 = { class: "tt-cabinet__content relative" }
const _hoisted_6 = { class: "tt-cabinet__content-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sidebar = _resolveComponent("sidebar")!
  const _component_main_header = _resolveComponent("main-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_DrawerContainer = _resolveComponent("DrawerContainer")!
  const _component_auth_provider = _resolveComponent("auth-provider")!

  return (_openBlock(), _createBlock(_component_auth_provider, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_DrawerContainer, {
          "condense-checker": $setup.sizeChecker,
          "full-width": true,
          condensable: true
        }, {
          element: _withCtx(({ condensed, condensable, opened, toggle, close, open }) => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_sidebar, {
                condensed: condensed,
                condensable: condensable,
                opened: opened,
                toggle: toggle,
                close: close,
                open: open
              }, null, 8, ["condensed", "condensable", "opened", "toggle", "close", "open"])
            ])
          ]),
          content: _withCtx(({ condensed, condensable, opened, toggle, open, close }) => [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_main_header)
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  (_openBlock(), _createBlock(_component_router_view, {
                    key: $setup.route.name
                  }))
                ])
              ])
            ])
          ]),
          _: 1
        }),
        (_openBlock(), _createBlock(_resolveDynamicComponent($setup.isDesktop ? 'div' : 'filters-modal')))
      ])
    ]),
    _: 1
  }))
}