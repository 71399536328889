import {
  logoutResource,
} from '@/services/auth.service'

import { useConfirmDialog } from '@/composables/useConfirm'

export default async function useLogout($t) {
  return new Promise( (resolve, reject) => {
    const question = $t('Are you sure you want to log out?')
    useConfirmDialog({question}).then(() => {
      logoutResource.execute().then(() => {
        resolve(true)
      }, err => {
        reject(err)
      })
    })
  })
}