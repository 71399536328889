import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tt-icon-tooltip__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tt-icon-tooltip", {'last' : $setup.props.isLast[0] == $setup.props.isLast[1] - 1}])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["tt-icon-tooltip__wrapper", $setup.props.filled ? 'fill' : 'stroke'])
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["tt-icon-tooltip__tooltip", `tooltip--${$setup.props.position}`])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", null, _toDisplayString($setup.props.title), 1)
      ])
    ], 2)
  ], 2))
}