/* eslint-disable */
// import Confirm from '@app/UiElements/Confirm';
// import ModalBaseTemplate from '@app/UiElements/Modals/ModalTemplate.vue';
// import * as QRCode from 'qrcode'
import is from 'ramda/src/is'
// import executeWithRefresh from '@/modules/refresher'

// import {
//   getOptionsResource,
// } from '@/services/options.service.js'

export function modalOpener({
  modal = ModalBaseTemplate,
  props = {},
  actor = () => { },
  value = undefined,
  classes = [],
  config = {},
}) {
  const promise = arg => new Promise((resolve, reject) => {
    if (arg) {
      resolve(arg);
      return;
    }
    reject();
  }).then(result => {
    actor(value || result);
  });

  this.$modal.show(
    modal,
    { ...props, promise }, {
      classes,
      ...config,
    }
  );
}

export function confirmWithPromise(question, $this) {
  return new Promise((res, rej) => {
    const pr = arg => new Promise((resolve, reject) => {
      if (true == arg) {
        resolve(arg)
      } else {
        reject(new Error('...'))
      }
    }).then(result => {
      res(result)
    }, err => {
      rej()
    })
    $this.$modal.show(
        Confirm,
        {
          question,
          promise: pr,
        }, {
          height: 'auto',
          width: '300px',
          adaptive: true,
        }
    )
  })
}

export function setColors(buttonMain, buttonAdditional, sidebar) {
  const rootElement =  document.documentElement
  rootElement.style.setProperty('--theme-accent-main', buttonMain)
  rootElement.style.setProperty('--theme-primary-dark', buttonAdditional)
  rootElement.style.setProperty('--theme-sidebar-main', sidebar)
}

// export function qrGenerator(objects) {
//   const qrCodeOptions = {
//     errorCorrectionLevel: 'M',
//     type: 'svg',
//     color: {
//       dark: '#253E58',
//       light: '#fff',
//     },
//     height: 800,
//     width: 800
//   };

//   const qrUrlGetter = (target) => {
//     const jsonObj = JSON.stringify(target)
//     const img = { rsc: '' }
//     QRCode.toDataURL(jsonObj, qrCodeOptions, (err, url) => {
//       img.src = url;
//     })
//     return img.src
//   }

//   const printWindow = window.open('', '', 'width=635,height=800')
//   printWindow.document.open()
//   printWindow.document.write('<html><head><title>QR-codes</title></head><body>')
//   printWindow.document.write('<div style="display: flex">');
//   objects.qr.forEach(element => {
//     printWindow.document.write('<div style="width: 150px">');
//     const qrCodeImg = `<img src="${qrUrlGetter(element)}" style="height: 150px">`
//     printWindow.document.write(qrCodeImg)
//     const subtitle = `<p style="color: #253E58; text-align: center; margin: 0">${element.rentObjectType}<p/>`
//     printWindow.document.write(subtitle);
//     printWindow.document.write('</div>');
//   });
//   printWindow.document.write('</div>');
//   printWindow.document.write('</body></html>');
//   printWindow.document.close();
//   // printWindow.print();
//   // printWindow.close();
// }

// export async function optionsGetter(title, field,) {
//   const preparedData = { object_title: title, option: field?.optionsTypeTitle || field?.name  || field?.optionsType }
//   if('options' in field && !field.options.length) {
//     try { const { data: { item } } = (await executeWithRefresh( getOptionsResource, {...preparedData}))
//     field.options = item[field.name]?.choices || item[field.optionsType]?.choices
//   } catch (e) {
//     return Promise.reject(e)
//   }
//   }
// }

export function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

// export function toBase64(file) {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => resolve(reader.result);
//     reader.onerror = error => reject(error);
//   });
// }


/**
 * @param {string} path - name of key(
 *  f.e. Object is -{key: {child_key: 'test'}}, path - key, child_key
 * )
 */
export function pathGetter(path) {
  if (!Array.isArray(path)) {
    path = Array.from(arguments)
  }

  return item => path.reduce((acc, x) => (acc ? acc[x] : '-'), item)
}

export class Field {
  constructor(key, label = null, getter = null, parameters = null, hidden = false) {
    if (null !== label && is(Function, label)) {
      label = null
      getter = label
      parameters = getter
    }

    if (null !== getter && !is(Function, getter)) {
      getter = null
      parameters = getter
    }

    this.key = key
    this.label = label || this.key.toTitle()
    this.getter = getter || pathGetter(this.key)
    this.parameters = parameters || null
    this.hidden = hidden || null
  }

  getValue(obj) {
    return this.getter(obj)
  }
}
