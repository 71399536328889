import { RouteRecordRaw } from 'vue-router';
import { route } from '@/router/utils'
// import { allPermissions } from '@access/index'
// import { accessPermission } from '@access/permissions'

// const {
//   STAFF: {
//     TRANSACTION: {
//       VIEW_LIST
//     },
//   },
// } = allPermissions

const protocolsTrashList = () => import('../protocols/views/List.vue')
// const protocolTrashCreat = () => import('../protocols/views/Create.vue')

export const PROTOCOLS_TRASH_LIST_PAGE_NAME = 'cabinet:trash-protocols-list'
// export const PROTOCOL_EDIT_PAGE_NAME = 'cabinet:protocol-edit'
// export const PROTOCOL_CREATE_PAGE_NAME = 'cabinet:protocol-create'

// URLs
export const PROTOCOLS_TRASH_LIST_PAGE_URL = ''
// export const PROTOCOL_EDIT_PAGE_URL = 'edit/:id?/'
// export const PROTOCOL_CREATE_PAGE_URL = 'create/'

export function createRoutes(): RouteRecordRaw[] {
  return [
    route(PROTOCOLS_TRASH_LIST_PAGE_URL, protocolsTrashList, PROTOCOLS_TRASH_LIST_PAGE_NAME, {
      meta: {
        breadcrumb: 'trash-protocols',
        isMain: true
        // ...accessPermission(VIEW_LIST)
      }
    }),
    // route(PROTOCOL_EDIT_PAGE_URL, protocolTrashCreat, PROTOCOL_EDIT_PAGE_NAME, {
    //   meta: {
    //     breadcrumb: 'protocols',
    //     isNew: false
    //     // ...accessPermission(VIEW_LIST)
    //   }
    // }),
    // route(PROTOCOL_CREATE_PAGE_URL, protocolCreat, PROTOCOL_CREATE_PAGE_NAME, {
    //   meta: {
    //     breadcrumb: 'protocols',
    //     isNew: true
    //     // ...accessPermission(VIEW_LIST)
    //   }
    // }),
  ]
}
