/* eslint-disable */
export const tableWrapper = {
  beforeMount: (node: any) => {
    const tables = Array.from(node.querySelectorAll('table'))
    const zero = 0
    if (zero < tables.length) {
      tables.forEach((el: any) => {
        const wrapper = document.createElement('div')
        el.parentNode.insertBefore(wrapper, el)
        wrapper.appendChild(el)
        wrapper.classList.add('table-wrapper')
      })
    }
  }
}
