import { defineStore } from '@/modules/pinia-hot'

import {
  languagesListResource,
} from '@/services/languages.service'

const id = 'languages'

export const useLangStore = defineStore(id, {
  state: () => ({
    list: [],
    required: ''
  }),
  getters: {
  },
  actions: {
    async receive() {
      const { data: { item } } = await languagesListResource.execute({})
      this.list = item.languages
      this.required = item.requiredLang
    },
  },
})
