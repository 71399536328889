import { createApp, App } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import { createPinia } from 'pinia'
import { createApplication } from '@/app'

import './styles/index.sass'

export const app = createApplication({
  createApp, createRouter, createWebHistory, createPinia,
})

window.app = app

declare global {
  interface Window {
    USER_PERMISSIONS: any
    ALL_PERMISSIONS: any,
    SHOP_LINK: any,
    IS_DARK: boolean,
    app: App
  }
}

const ttBody = document.querySelector('body')

const colorSchemeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

function handleColorSchemeChange(e) {
  const DARK = 'dark'
  if (e.matches) {
    ttBody.classList.add(DARK)
  } else {
    ttBody.classList.remove(DARK)
  }
}

colorSchemeMediaQuery.addEventListener('change', handleColorSchemeChange);


handleColorSchemeChange(colorSchemeMediaQuery);