import {
  createSender,
  createReceiver,
  createUpdater,
  r
} from '@/resource/resource'

import {
  prefixAPI,
  API_BASE_URL,
} from '@/resource/api'

const MODEL = 'feedback'
const COUNTER_MODEL = 'sidebar-counter'

const QUESTION_COUNT_LINK = prefixAPI('/', COUNTER_MODEL)

const QUESTIONS_LIST_LINK = prefixAPI('/question/list/{?limit,offset,filters}', MODEL)
const QUESTION_FILTERS_LIST_LINK = prefixAPI('/question/list/filters/', MODEL)

const QUESTION_DETAILS_LINK = prefixAPI('/question/{id}/', MODEL)
const ANSWER_SEND_LINK = prefixAPI('/answer/send/', MODEL)

export const questionCountResource = createReceiver(QUESTION_COUNT_LINK)

export const questionsListResource = createReceiver(QUESTIONS_LIST_LINK)
export const questionsFiltersListResource = createReceiver(QUESTION_FILTERS_LIST_LINK)
export const questionDetailsResource = createReceiver(QUESTION_DETAILS_LINK)

export const answerSendResource = createSender(ANSWER_SEND_LINK)

