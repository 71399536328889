import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tt-filter-tabs" }
const _hoisted_2 = { class: "tt-filter-tabs__tabs-wrapper" }
const _hoisted_3 = { class: "tt-filter-tabs__code" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "tt-filter-tabs__code" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["tt-filter-tabs__tab pointer", {'active' : !$setup.val}]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.setValue({})))
      }, [
        _createElementVNode("div", _hoisted_3, _toDisplayString($setup.$t('All')), 1)
      ], 2),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.props.list, (el) => {
        return (_openBlock(), _createElementBlock("div", {
          key: el.id,
          class: _normalizeClass(["tt-filter-tabs__tab pointer", {'active' : $setup.val == el?.id || $setup.val?.id == el?.id}]),
          onClick: ($event: any) => ($setup.setValue(el))
        }, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(el.caption), 1)
        ], 10, _hoisted_4))
      }), 128))
    ])
  ]))
}