import {
  createSender,
  createReceiver,
  createUpdater,
  r
} from '@/resource/resource'

import {
  prefixAPI,
  API_BASE_URL,
} from '@/resource/api'

const MODEL = 'languages'

const LANGUAGES_LIST_LINK = prefixAPI('/', MODEL)

export const languagesListResource = createReceiver(LANGUAGES_LIST_LINK)
