import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tt-auth-page" }
const _hoisted_2 = { class: "tt-auth-page__form tt-auth-page__form--theme" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_auth_form = _resolveComponent("auth-form")!
  const _component_password_reset = _resolveComponent("password-reset")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!$setup.isResetForm)
        ? (_openBlock(), _createBlock(_component_auth_form, {
            key: 0,
            onChangeForm: $setup.changeForm
          }))
        : (_openBlock(), _createBlock(_component_password_reset, {
            key: 1,
            info: $setup.username,
            "is-main": true,
            onChangeForm: $setup.changeForm
          }, null, 8, ["info"]))
    ])
  ]))
}