import { RouteRecordRaw } from 'vue-router';
import { route } from '@/router/utils'
import { allPermissions } from '@access/index'
import { accessPermission } from '@/permissions/permissions'

const {
  STAFF: {
    TAG: {
      VIEW
    },
  },
} = allPermissions

const tagsList = () => import('./views/List.vue')

export const TAGS_LIST_PAGE_NAME = 'cabinet:tags-list'
// URLs
export const TAGS_LIST_PAGE_URL = ''

export function createRoutes(options): RouteRecordRaw[] {
  return [
    route(TAGS_LIST_PAGE_URL, tagsList, TAGS_LIST_PAGE_NAME, {
      meta: {
        breadcrumb: 'tags',
        ...accessPermission([VIEW], options),
        isMain: true
      }
    }),
  ]
}
