
import { defineComponent, SetupContext, onBeforeMount, computed } from 'vue'
import { useUserStore } from '@/stores/user'

export default defineComponent({
  setup(props: unknown, { slots }: SetupContext) {
    const userStore = useUserStore()
    onBeforeMount(async () => {
      console.log('GETTER')
      await userStore.receive()
    })
    return () => slots.default?.()
  },
})
