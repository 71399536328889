import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "cabinet-sidebar-menu-section__icon"
}
const _hoisted_2 = {
  key: 1,
  class: "cabinet-sidebar-menu-section__label"
}
const _hoisted_3 = { class: "cabinet-sidebar-menu-section__content" }
const _hoisted_4 = {
  key: 0,
  class: "cabinet-sidebar-menu-section__menu"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cabinet_sidebar_menu_element = _resolveComponent("cabinet-sidebar-menu-element")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["cabinet-sidebar-menu-section", [{'is-condensed': $props.condensed}, _ctx.element?.class]])
  }, [
    ($props.label)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.to ? "router-link" : "div" ), {
          key: 0,
          class: "cabinet-sidebar-menu-section__caption",
          to: _ctx.to
        }, {
          default: _withCtx(() => [
            ($props.icon)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _renderSlot(_ctx.$slots, "icon", { icon: $props.icon }, () => [
                    _createElementVNode("span", {
                      class: _normalizeClass(`icon-${$props.icon}`),
                      size: "lg"
                    }, null, 2)
                  ])
                ]))
              : _createCommentVNode("", true),
            ($props.label)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _renderSlot(_ctx.$slots, "label", { label: $props.label }, () => [
                    _createTextVNode(_toDisplayString($props.label), 1)
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 3
        }, 8, ["to"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        ($props.menu)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.menu, (element, i) => {
                return (_openBlock(), _createElementBlock("li", {
                  class: _normalizeClass(["cabinet-sidebar-menu-section__element", element?.class])
                }, [
                  _renderSlot(_ctx.$slots, "menu-element", {}, () => [
                    (_openBlock(), _createBlock(_component_cabinet_sidebar_menu_element, _mergeProps({
                      condensed: $props.condensed,
                      drop: element.drop || false,
                      active: $setup.active,
                      key: element.key,
                      slug: element.key,
                      onOpen: ($event: any) => ($setup.changed(element.key)),
                      ref_for: true
                    }, element), null, 16, ["condensed", "drop", "active", "slug", "onOpen"]))
                  ])
                ], 2))
              }), 256))
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 2))
}