import { App } from 'vue'
import IEye from './i-eye.vue'
import IEyeBold from './i-eye-bold.vue'
import IEyeOff from './i-eye-off.vue'
import IArrowDown from './i-arrow-down.vue'
import IArrowDownThin from './i-arrow-down-thin.vue'
import IArrowDownLight from './i-arrow-down-light.vue'
import IProtocols from './i-protocols.vue'
import IComments from './i-comments.vue'
import IPhotoCamera from './i-photo-camera.vue'
import IDragg from './i-dragg.vue'
import IEdit from './i-edit.vue'
import IDelete from './i-delete.vue'
import IArrowLeft from "./i-arrow-left.vue";
import IArrowRight from "./i-arrow-right.vue";
import IKey from "./i-key.vue";
import IClose from "./i-close.vue";
import IDate from "./i-date.vue";
import ISearch from "./i-search.vue";
import ILoad from "./i-load.vue";
import IDropzone from "./i-dropzone.vue";
import IPdf from "./i-pdf.vue";
import ICheck from "./i-check.vue";
import ITrash from "./i-trash.vue";
import ITrashImage from "./i-trash-image.vue";
import IStore from "./i-store.vue";
import IPlus from "./i-plus.vue";
import ISave from "./i-save.vue";
import IFile from "./i-file.vue";
import IConversion from "./i-conversion.vue";
import IDocsUpload from "./i-docs-upload.vue";
import IRefresh from "./i-refresh.vue";
import IEmail from "./i-email.vue";
import IEmailSmall from "./i-email-small.vue";
import IPassword from "./i-password.vue";
import ILang from "./i-lang.vue";
import INotify from "./i-notify.vue";
import INotifyThin from "./i-notify-thin.vue";
import IDarkTheme from "./i-dark-theme.vue";
import ILightTheme from "./i-light-theme.vue";
import ILogout from "./i-logout.vue";
import IProfile from "./i-profile.vue";
import IProfileAvatar from "./i-profile-avatar.vue";
import ISidebarTriggerClose from "./i-sidebar-trigger-close.vue";
import ISidebarTrigger from "./i-sidebar-trigger.vue";
import IFullscreenOff from "./i-fullscreen-off.vue";
import IFullscreenOn from "./i-fullscreen-on.vue";
import IHide from "./i-hide.vue";
import IShow from "./i-show.vue";
import IArchive from "./i-archive.vue";
import IFilters from "./i-filters.vue";
import IUserSmall from "./i-user-small.vue";
import IOpen from "./i-open.vue";

const register = (app: App<Element>): void => {
  app
  .component('IEmailSmall', IEmailSmall)
  .component('IUserSmall', IUserSmall)
  .component('IFilters', IFilters)
  .component('IHide', IHide)
  .component('IShow', IShow)
  .component('IArchive', IArchive)
  .component('ISidebarTriggerClose', ISidebarTriggerClose)
  .component('IProfileAvatar', IProfileAvatar)
  .component('ITrashImage', ITrashImage)
  .component('IFullscreenOn', IFullscreenOn)
  .component('IFullscreenOff', IFullscreenOff)
  .component('ILightTheme', ILightTheme)
  .component('IEyeBold', IEyeBold)
  .component('ISidebarTrigger', ISidebarTrigger)
  .component('IProfile', IProfile)
  .component('ILogout', ILogout)
  .component('IDarkTheme', IDarkTheme)
  .component('INotifyThin', INotifyThin)
  .component('INotify', INotify)
  .component('IArrowDownThin', IArrowDownThin)
  .component('ILang', ILang)
  .component('IEmail', IEmail)
  .component('IPassword', IPassword)
  .component('IRefresh', IRefresh)
  .component('IFile', IFile)
  .component('ISave', ISave)
  .component('IPlus', IPlus)
  .component('IDocsUpload', IDocsUpload)
  .component('IConversion', IConversion)
  .component('IArrowDownLight', IArrowDownLight)
  .component('IStore', IStore)
  .component('ITrash', ITrash)
  .component('ICheck', ICheck)
  .component('IPdf', IPdf)
  .component('ILoad', ILoad)
  .component('IDropzone', IDropzone)
  .component('ISearch', ISearch)
  .component('IDate', IDate)
  .component('IClose', IClose)
  .component('IArrowDown', IArrowDown)
  .component('IEye', IEye)
  .component('IEyeOff', IEyeOff)
  .component('IProtocols', IProtocols)
  .component('IComments', IComments)
  .component('IPhotoCamera', IPhotoCamera)
  .component('IDragg', IDragg)
  .component('IEdit', IEdit)
  .component('IDelete', IDelete)
  .component('IArrowRight', IArrowRight)
  .component('IArrowLeft', IArrowLeft)
  .component('IKey', IKey)
  .component('IOpen', IOpen)
}
export default {
  register,
}
