import { App } from 'vue'
import Cabinet from './cabinet/views/Cabinet.vue'
import FiltersModal from './cabinet/components/modals/FiltersModal.vue'
import Auth from './auth/Auth.vue'

const register = (app: App<Element>): void => {
  app
  .component('Cabinet', Cabinet)
  .component('Auth', Auth)
  .component('FiltersModal', FiltersModal)
}

export default {
  register,
}
