import { vModelDynamic as _vModelDynamic, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "d-control-input d-control-input--appearance_bordered relative" }
const _hoisted_2 = ["type", "pattern"]
const _hoisted_3 = {
  key: 1,
  class: "input-icon-element"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i_eye_bold = _resolveComponent("i-eye-bold")!
  const _component_i_eye_off = _resolveComponent("i-eye-off")!
  const _component_i_search = _resolveComponent("i-search")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", _mergeProps({ class: "d-control-input__element" }, _ctx.$attrs, {
      autocomplete: "off",
      type: $setup.inputType,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.val) = $event)),
      onInput: $setup.setValue,
      pattern: {'\d*' : $setup.NUMBER_INPUT_TYPE == $setup.inputType}
    }), null, 16, _hoisted_2), [
      [_vModelDynamic, $setup.val]
    ]),
    _renderSlot(_ctx.$slots, "label"),
    ($setup.isPasswordField)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "change-type",
          onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.toggleType()))
        }, [
          ($setup.inputType === $setup.p)
            ? (_openBlock(), _createBlock(_component_i_eye_bold, { key: 0 }))
            : (_openBlock(), _createBlock(_component_i_eye_off, { key: 1 }))
        ]))
      : _createCommentVNode("", true),
    ($setup.props.type === $setup.s)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_i_search)
        ]))
      : _createCommentVNode("", true)
  ]))
}