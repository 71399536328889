import { Permission } from '@aspectus/permissions'
import { Pinia } from 'pinia'
import { isRef, unref } from 'vue'
import { curry, path } from 'ramda'
import { Or, And } from '@aspectus/permissions'
import { accessConfig } from '@/packages/vue-router-permissions'
import Route403 from '@m/cabinet/views/Route403.vue'
import { userPermissions } from './index.js'


interface UserInfo {
  info: {
    userType: string,
    profileType: {
      label: string,
      value: string
    }
  }
  isAuthenticated: () => boolean
}

interface User extends UserInfo {
  determined: boolean,
}

type GetterResult = { user: User }
export function waitForUser(
  state: unknown,
  getter: (state: unknown) => GetterResult,
  timeout = 5000,
  tick = 3
): Promise<User> {
  return new Promise((resolve, reject) => {
    const maxAttempts = tick
    let attempts = 0

    const interval = setInterval(() => {
      const userState = getter(state)
      if (userState) {
        let { user } = userState
  
        if (!user) {
          clearInterval(interval)
          reject()
          return
        }
  
        if (isRef(user)) {
          user = unref(user) as User
        }
  
        if (user.determined) {
          clearInterval(interval)
          if (user.isAuthenticated()) {
            resolve(user)
          } else {
            reject()
          }
          return
        }

      }

      attempts++
      if (attempts >= maxAttempts) {
        clearInterval(interval)
        reject()
      }
    }, tick)

    // setTimeout(() => {
    //   clearInterval(interval)
    // }, timeout)
  })
}
export class UserCheckerPermissionBase extends Permission {
  getter: (state: unknown) => GetterResult
  timeout: number
  tick: number
  constructor({
    getter,
    timeout = 5000,
    tick = 10,
  }: { getter?: (state: unknown) => GetterResult; timeout?: number; tick?: number } = {}) {
    super()
    this.getter = getter || (() => ({ user: { determined: false, isAuthenticated: () => false, info: { userType: '' } } as User }))
    this.timeout = timeout
    this.tick = tick
  }

  getStore(vm: { pinia: Pinia }, { pinia }: { pinia?: Pinia } = {}): Pinia {
    return (vm && vm.pinia) || pinia
  }

  waitForUser(
    vm: { pinia: any },
    args?: [{ pinia?: Pinia }]
  ): Promise<User> {
    const store = vm?.pinia
    return waitForUser(store, this.getter, this.timeout, this.tick)
  }

  checkUser(...arg: unknown[]): boolean {
    return false
  }
  onHasAccess(
    vm: { pinia: Pinia },
    ...args
  ): Promise<void> {
    return this.waitForUser(vm, ...args).then((user) => {
      if (!this.checkUser(user, vm, ...args)) {
        throw new Error('Access denied')
      }
    })
  }
}

export class UserCheckerPermission extends UserCheckerPermissionBase {
  checker: (...arg: unknown[]) => boolean
  constructor({
    checker,
    ...rest
  }: {
    checker: (...arg: unknown[]) => boolean
    [key: string]: unknown
  }) {
    super(rest)
    this.checker = checker
  }

  checkUser(...arg: unknown[]): boolean {
    return this.checker(...arg)
  }
  onHasAccess(
    vm: { pinia: Pinia },
    params
  ): any {
    return new Promise<any>((resolve, reject) => {
      this.waitForUser(params, params).then((user) => {
        if (!this.checkUser(user, vm, params)) {
          // throw user;
          reject('user')
        }
        resolve(user)
      }, err => reject(err))
    })
    return this.waitForUser(params, params).then((user) => {
      if (!this.checkUser(user, vm, ...params)) {
        throw user;
      }
    })
  }
}

export function checkAuth(
  checker: (...arg: unknown[]) => boolean,
  config = {},
  Base = UserCheckerPermission
): UserCheckerPermission {
  return new Base(Object.assign({ checker, getter: path(['state', 'value', 'user', 'user']) },  config ), )
}

class SimplePermission extends Permission {
  checker: (...args: unknown[]) => boolean
  constructor({
    checker,
    ...rest
  }: {
    checker: (...args: unknown[]) => boolean
    [key: string]: unknown
  }) {
    super(rest)
    this.checker = checker
  }

  onHasAccess(...args: unknown[]): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.checker(...args)) {
        resolve()
      } else { 
        reject()
      }
    })
  }
}

export function simpleChecker<T>(
  checker: (arg: T) => boolean,
  Base = SimplePermission
): SimplePermission {
  return new Base({ checker })
}

// Usage example
const simpleCheckPermission = (checker: (user: User) => boolean): SimplePermission => simpleChecker(checker)
const userCheckPermission = (checker: (user: User) => boolean) : UserCheckerPermission => checkAuth(checker)

// Export `isType` function as it is part of the original code but not provided here.
export const isType = (type: string, user: { info: { userType: string } }): boolean => user.info.userType === type;

export const isPermittedSimple = (permissions: string[]): boolean => {
  return !!(
    userPermissions &&
    permissions.every((permission) => userPermissions.includes(permission))
  )
}

export const checkPermissions = perms => {
  return isPermittedSimple(perms)
}
  
export const isPermitted = curry(
  (permissions: string[], user: User): boolean => 
    !!(
      userPermissions &&
      permissions.every((permission) => userPermissions.includes(permission))
    )
)

export const toBeNotAuthenticated = simpleCheckPermission(
  (user: UserInfo): boolean => !user.isAuthenticated()
)

export const toHasOwnPermission = (permissions: string[]): SimplePermission =>
  simpleCheckPermission((user: User): boolean => isPermitted(permissions)(user))

export const toHasPermission = (permissions: string[]): InstanceType<typeof Or> => {
  return new Or(toHasOwnPermission(permissions))
}


export const accessPermission = (
  permissions: string[],
  options: { [key: string]: unknown }
): { [key: string]: unknown } => {
  return {
    accessConfig: accessConfig.apply(this, [
      toHasPermission(permissions),
      { 
        name: 'cabinet:no-access'
      },
      options,
    ]),
  }
}

export const recaptcha_site_key = window?.recaptcha_site_key

