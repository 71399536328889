import { renderSlot as _renderSlot, withModifiers as _withModifiers, mergeProps as _mergeProps, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href", "onClick"]
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return ($props.to)
    ? (_openBlock(), _createBlock($setup["RouteAvailable"], {
        key: 0,
        to: $props.to
      }, {
        default: _withCtx(() => [
          (_openBlock(), _createBlock(_component_router_link, {
            class: "cabinet-sidebar-menu-link",
            key: $props.href || $props.to && $props.to.name || Date.now() || 'sdfsd',
            to: $props.to,
            custom: "",
            exact: true,
            "exact-path": false
          }, {
            default: _withCtx(({ isActive, isExactActive, activeClass, href, navigate }) => [
              _createElementVNode("a", _mergeProps({ class: "cabinet-sidebar-menu-link" }, _ctx.$attrs, {
                href: href,
                class: { 'is-active': isActive, 'router-link-exact-active': isExactActive, 'is-disabled': $props.disabled },
                onClick: _withModifiers(($event: any) => ($props.disabled || $props.hasChild ? $setup.emit('clicked') : navigate()), ["prevent"])
              }), [
                _renderSlot(_ctx.$slots, "default")
              ], 16, _hoisted_1)
            ]),
            _: 3
          }, 8, ["to"]))
        ]),
        _: 3
      }, 8, ["to"]))
    : ($props.href)
      ? (_openBlock(), _createElementBlock("a", _mergeProps({
          key: 1,
          class: ["cabinet-sidebar-menu-link", { 'is-disabled': $props.disabled  }],
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($props.disabled ? $event.preventDefault() : null)),
          href: $props.href
        }, _ctx.$attrs), [
          _renderSlot(_ctx.$slots, "default")
        ], 16, _hoisted_2))
      : _createCommentVNode("", true)
}