const PROCEDURES_KEY = 'procedures'
const CONVERSATIONS_KEY = 'conversations'
const TRASH_KEY = 'trash'
const PAGE_NOT_FOUND_KEY = 'page-not-found'
const QUESTIONS_KEY = 'questions'
const QUESTIONS_VIEW_KEY = 'questions-view'
const ACCESS_DENIED_KEY = 'access-denied'
const PROTOCOLS_KEY  = 'protocols'
const PROTOCOL_EDIT_KEY = 'protocol-edit'
const PROTOCOL_ADD_KEY = 'protocol-add'
const TRASH_PROTOCOLS_KEY = 'trash-protocols'
const SOURCES_KEY = 'sources'
const SOURCES_ADD_KEY = 'sources-add'
const SOURCES_EDIT_KEY = 'sources-edit'
const PDF_KEY = 'pdf'
const TRASH_PDF_KEY = 'trash-pdf'
const TAGS_KEY = 'tags'
const TRASH_TAGS_KEY = 'trash-tags'
const PROFILE_KEY = 'profile'
const NOTIFY_KEY = 'notify'
const NOTIFICATIONS_KEY = 'notifications'
const INTERNAL_ERROR_KEY = 'internal-error'
const DELETED_PROTOCOLS_KEY = 'deleted-protocols'
const PROTOCOL_SOURCES_KEY = 'protocol-sources'
const FILES_KEY = 'files'
const DELETED_FILES_KEY = 'deleted-files'
const DELETED_TAGS_KEY = 'deleted-tags'
const NOT_FOUND_KEY = 'not-found'
const FORBIDDEN_KEY = 'forbidden'
const CREATE_KEY = 'create'
const EDIT_KEY = 'edit'
const VIEW_KEY = 'view'

export const GET_BREADCRUMBS_LABELS = $t => ({
  [PAGE_NOT_FOUND_KEY]: $t('Page is not found'),
  [QUESTIONS_KEY]: $t('Protocols'),
  [QUESTIONS_VIEW_KEY]: $t('Question view'),
  [ACCESS_DENIED_KEY]: $t('Access is denied'),
  [PROTOCOLS_KEY]: $t('Protocols'),
  [PROTOCOL_EDIT_KEY]: $t('Edit protocol'),
  [PROTOCOL_ADD_KEY]: $t('Add protocol'),
  [TRASH_PROTOCOLS_KEY]: $t('Protocols'),
  [SOURCES_KEY]: $t('Sources'),
  [SOURCES_ADD_KEY]: $t('Add sources'),
  [SOURCES_EDIT_KEY]: $t('Edit sources'),
  [PDF_KEY]: $t('Files'),
  [TRASH_PDF_KEY]: $t('Files'),
  [TAGS_KEY]: $t('Tags'),
  [TRASH_TAGS_KEY]: $t('Tags'),
  [PROFILE_KEY]: $t('Profile info'),
  [NOTIFY_KEY]: $t('Notifications'),
  [INTERNAL_ERROR_KEY]: $t('Internal error'),
})

export const GET_BREADCRUMBS_INNER_LABELS = $t => ({
  [PROCEDURES_KEY]: $t('Procedures'),
  [CONVERSATIONS_KEY]: $t('Conversation'),
  [TRASH_KEY]: $t('Trash'),

  [PROTOCOLS_KEY]: $t('Protocols'),
  [PROTOCOL_SOURCES_KEY]: $t('Sources'),
  [TAGS_KEY]: $t('Tags'),
  [FILES_KEY]: $t('Files'),
  [SOURCES_KEY]: $t('Sources'),
  
  [QUESTIONS_KEY]: $t('Protocols'),
  
  [PROFILE_KEY]: $t('Profile info'),
  [NOTIFICATIONS_KEY]: $t('Notifications'),
  
  [DELETED_PROTOCOLS_KEY]: $t('Protocols'),
  [DELETED_TAGS_KEY]: $t('tags'),
  [DELETED_FILES_KEY]: $t('Files'),
  
  [CREATE_KEY]: $t('New'),
  [EDIT_KEY]: $t('Edit'),
  [VIEW_KEY]: $t('View'),
  
  [NOT_FOUND_KEY]: $t('Page not found'),
  [FORBIDDEN_KEY]: $t('Access denied'),
  [INTERNAL_ERROR_KEY]: $t('Internal error'),
})