import urlLib from 'x-url'

const SLICE_COUNT = 1
const MIN_LANG_LENGTH = 2

export const DEFAULT_LANGUAGE = 'ru'

export function clean(url) {
  return url.replace(/\/+/gim, '/')
}

export function getRelative(url = '') {
  if (!url.startsWith('http')) {
    return url
  }

  const object = urlLib.parse(url)

  return object.path
}

export function getPrefixed(url, prefix = null) {
  if (!prefix) {
    return url
  }

  return clean(prefix + getRelative(url))
}

export function getLanguageCode(url = '') {
  const [lang] = getRelative(url)
    .replace(/^[/]+|[/]+$/g, '')
    .split('/', SLICE_COUNT)

  if (!lang || lang.length > MIN_LANG_LENGTH) {
    return DEFAULT_LANGUAGE
  }

  return lang
}

export const CURRENT_LANGUAGE = (window && window.location && getLanguageCode(window.location.href)) || DEFAULT_LANGUAGE

export function removeLanguage(path = '') {
  const currentLanguageCode = getLanguageCode(path)

  if (currentLanguageCode === DEFAULT_LANGUAGE) {
    return path
  }

  return path.slice(currentLanguageCode.length + SLICE_COUNT)
}

export function prefixLanguage(url = '', { language = CURRENT_LANGUAGE } = {}) {
  const path = removeLanguage(getRelative(url))

  if (!language || language === DEFAULT_LANGUAGE) {
    return path
  }

  return getPrefixed(path, `/${language}/`)
}

