import type { Router } from 'vue-router'
import { defineStore } from '@/modules/pinia-hot'

import {
  questionCountResource,
} from '@/services/questions.service'

const id = 'question'

export const useQuestion = defineStore(id, {
  state: () => ({
    unreadCount: '',
  }),
  getters: {
  },
  actions: {
    async getUnread() {
      const { data: { item } } = await questionCountResource.execute({})
      this.unreadCount = item.conversionProtocols
    },
  },
})
