<template>
<svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_109_959)">
<path d="M18 2.30707H8.32852V0.547852H18V2.30707ZM5.28082 4.59574L3.36656 6.50965H18V8.26887H3.36762L5.28082 10.1817L4.03664 11.4259L0 7.38926V7.3882L1.24418 6.14508L4.03664 3.35156L5.28082 4.59574ZM18 14.2321H8.32852V12.4729H18V14.2321Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_109_959">
<rect width="18" height="13.6842" fill="white" transform="translate(0 0.547852)"/>
</clipPath>
</defs>
</svg>
</template>