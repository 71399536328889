<template>
<svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_109_1156)">
<path d="M0 11.925H9.67148V13.6842H0V11.925ZM12.7192 9.63631L14.6334 7.72241H0V5.96319H14.6324L12.7192 4.05034L13.9634 2.80616L18 6.8428V6.84385L16.7558 8.08698L13.9634 10.8805L12.7192 9.63631ZM0 -1.62125e-05H9.67148V1.7592H0V-1.62125e-05Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_109_1156">
<rect width="18" height="13.6842" fill="white" transform="matrix(-1 0 0 -1 18 13.6842)"/>
</clipPath>
</defs>
</svg>
</template>