<script>
import { ref, defineComponent } from 'vue'

export default defineComponent({
  name: 'SidebarTrigger',
  props: {
    inputRef: {
      required: false,
      default: ""
    },
  },
  setup(props, { slots }) {
    const sidebarIsOpen = ref(false)
    const { body } = document

    function setSidebarToggle(event) {
      sidebarIsOpen.value = !sidebarIsOpen.value
      body.classList.toggle('is-overflow')

    }

    function closeSidebar(event) {
      if(sidebarIsOpen.value) {
        sidebarIsOpen.value = false
        body.classList.remove('is-overflow')
      }
    }

    function setSidebarTrigger(trigger) {
      sidebarIsOpen.value = trigger
      body.classList.toggle('is-overflow')
    }

    return () => slots.default({
      sidebarIsOpen: sidebarIsOpen.value,
      sidebarTrigger: setSidebarTrigger,
      sidebarToggle: setSidebarToggle,
      sidebarClose: closeSidebar,
    })
  },
})
</script>
