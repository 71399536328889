import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "tt-theme-dark-toggler"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i_light_theme = _resolveComponent("i-light-theme")!
  const _component_i_dark_theme = _resolveComponent("i-dark-theme")!

  return (!$setup.props.hidden)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "theme-icon-stroke tt-theme-dark-toggler",
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.toggleTheme(!$setup.isDark)))
        }, [
          ($setup.isDark)
            ? (_openBlock(), _createBlock(_component_i_light_theme, { key: 0 }))
            : (_openBlock(), _createBlock(_component_i_dark_theme, { key: 1 }))
        ])
      ]))
    : _createCommentVNode("", true)
}