import { defineStore } from '@/modules/pinia-hot'
import {
  personalDataResource,
} from '@/services/profile.service'
import { User as BaseUser } from './base/user'
/* eslint-disable */
const id = 'user'

export const useUserStore = defineStore(id, {
  state: () => ({
    profile: '',
    user: new BaseUser({}, false),
  }),
  getters: {
    getUserInfo: (state) => state.profile,
  },
  actions: {
    async receive() {
      try {
        const { data: { item } } = await personalDataResource.execute({})
        this.profile = item
        this.user = new BaseUser(item)
      } catch (e) {
        
      }
    },
  },
})