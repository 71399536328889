import { ref, computed, watch, defineComponent } from 'vue';
import { updateValue } from '@aspectus/selection-controller';
import { renderSlim } from '@aspectus/vue-utils';
import { Tag } from '@aspectus/vue-tag';

const removeNulls = items => items.filter(x => x !== null);

export default defineComponent({
  name: 'vue-all-selection-controller',
  props: {
    items: Array,
    amount: {
      type: Number,
      default: 0,
    },
    pageAmount: {
      type: Number,
      default: 0,
    },
    keyGetter: Function,
  },
  setup(props, { emit, slots }) {
    const isTotal = ref(false);
    const excluded = ref([]);
    const selected = ref([]);

    const idsMap = (elements) => {
      return elements.reduce((acc, x) => {
        if (!x) {
          return acc;
        }
        acc[props.keyGetter(x)] = true;
        return acc;
      }, {});
    };

    const excludedIds = computed(() => idsMap(excluded.value));
    const selectedIds = computed(() => idsMap(selected.value));

    const notExcluded = (item) => !excludedIds.value[props.keyGetter(item)];

    const visuallySelected = computed(() => {
      return isTotal.value ? props.items.filter(notExcluded) : selected.value;
    });

    const selectedAmount = computed(() => {
      return isTotal.value ? props.amount - excluded.value.length : selected.value.length;
    });

    const isAll = computed(() => {
      return isTotal.value ? excluded.value.length === 0 : (props.items && (selected.value.length >= props.items.length));
    });

    const isSelected = computed(() => isTotal.value || selected.value.length > 0);

    const context = computed(() => ({
      selectedAmount: selectedAmount.value,
      isAll: isAll.value,
      isSelected: isSelected.value,
      isTotal: isTotal.value,
      changeTotal,
      excluded: excluded.value.map(props.keyGetter),
      selected: visuallySelected.value.map(props.keyGetter),
      changeSelected,
      keyGetter: props.keyGetter,
      checkSelected,
      checkAllSelected,
    }));

    const changeTotal = (value) => {
      changeSelected([], false);
      isTotal.value = value;
    };

    const changeSelected = (value) => {
      if (Array.isArray(value) && value.length === 0) {
        isTotal.value = false;
        excluded.value = [];
      }
      const key = isTotal.value ? 'excluded' : 'selected';
      if (key === 'excluded') {
        excluded.value = removeNulls(updateValue(value, excluded.value, props.keyGetter, true));
      } else {
        selected.value = removeNulls(updateValue(value, selected.value, props.keyGetter, true));
      }
    };

    const checkSelected = (item) => {
      return isTotal.value ? notExcluded(item) : !!selectedIds.value[props.keyGetter(item)];
    };

    const checkAllSelected = (item) => {
      props.items.every(item => selectedIds.value[item]);
    };

    watch(context, (newValue) => {
      emit('update', newValue);
    }, { immediate: true });

    return () => renderSlim(slots.default(context.value), Tag);
  },
});
